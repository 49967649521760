import React ,{useState,useEffect} from 'react'
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import "../Aboutus/About.css"
import img2 from "../images/10624.jpg"
import img1 from "../images/contact.jpg"
import shravan from "../images/shravan.jpg"
import tejeswini from "../images/tejeswini.png"
import bhargavi from "../images/bhargavi.jpg"
import {Container,Row,Col,Media} from "react-bootstrap"
import ToolTipdata from '../ToolTipdata'
import GridLoader from "react-spinners/GridLoader";
import context from "react-bootstrap/esm/AccordionContext";
import { FaUsers,FaEdit } from 'react-icons/fa' ;
import {AiFillFileText} from 'react-icons/ai';
function About() {  const [loading,setLoading]=useState(false)
  useEffect(()=>{
    setLoading(true)
    setTimeout(()=>{
        setLoading(false)
    },1000)
 },[])

  return (
    <div>
    {
      
      <div id="about">
      
      <Container className="vcontainer">
      
        <Row>
          <Col lg={9} sm={12}>
            <Row  className="strategic" data-aos="flip-up">
           
             
             <Col data-aos="flip-down" lg={5} sm={12}>
                 <h1 className="vhead">Lets Be Honest...</h1>
                 <p className="vdata">
                 We incorporated Prudence with a moto”Let’s grow together”. Planning the business resources coupled with compliances of law has become a challenge today. It requires extensive theoretical knowledge and practical experience. Shanmukha and his associates have sound theoretical knowledge and good practical experience. By sharing their knowledge and practical experience with the team members and clients, we ensure effective and timely redressal of issues arising on routine basis or that can arise later.
                 We enable our clients to recognize uncharted possibilities and assist them towards deploying sustainable strategies.
                 We strive to deliver personalized experience that makes decision making esier and simple.
                 With extensive service capability and seamless domain knowledge what we offer is reliable and trusted advice
                 </p>
             </Col>
           </Row>
          </Col>
        </Row>
      </Container>
      <Row className="whyprudence">
                      <center data-aos="flip-right"  className="wph">Why Prudence</center>

                      <Col className="wpid feature-box" lg={4} sm={12} md={12}>
                      <FaUsers className="wicons"/>
                      <h1>Right Team</h1>
                      </Col>
                      <Col className="wpid feature-box" lg={4} sm={12} md={12}>
                       <AiFillFileText className="wicons"/>
                       <h1>One stop solutions</h1></Col>
                      <Col className="wpid feature-box" lg={4} sm={12} md={12}>
                      <FaEdit className="wicons"/>
                      <h1>Start up Management</h1>
                      </Col>


                    </Row>

                    <Container fluid className="about" id="mentor">
                    <Row className="ceo">
                             <center className="ahead"> <Col data-aos="flip-up" sm={8}> Our <span className="text2">Amazing</span> Mentor </Col></center>
                             <center>

                             <Col><img data-aos="flip-up" className="aboutimg" src="images/shammu.jpg"/></Col>
                             <Col className="degree">
                             <p className='name' data-aos="fade-up">Shanmukha Bharadwaz</p>
                             <p data-aos="fade-left">Degrees</p>
                             </Col>
                             <Col data-aos="fade-up">
                             <p className="ceoheading">
                             He is in the practice for the past 5 years beginning by setting up own practice and building it up and Experience of 5 years in this field. He has good knowledge of departmental working at lower level up to the appellate level. He has varied experience in all areas of Audit, Investigation, Due Diligence, Taxation (Direct and Indirect), Valuation, Company Law Matters for both domestic and foreign companies, Management Consultancy. Work with clients includes IT companies (Small and medium sized), Start up , firms, banks, trusts, societies, charitable and educational institutions besides few renowned individuals. He has also experience in Cost management of Start-ups and Share valuation for fund raising. He is specialized in implementing internal controls and Internal Systems Audit in various startups and MNC’s Acting as a virtual chief financial officer (CFO) for more than 15 companies.
                             </p>
                             </Col>
                             </center>
                            </Row>
                            </Container>

    
    <Container fluid className='ourteam' id="team" >

    <Row className="ourteam"> 
    <center className="oteam">Our Amazing Team</center>
    <Col data-aos="flip-left" className="teammembers" lg={4} sm={12} md={12}>
    <img data-aos="flip-up" src={tejeswini} className="team_img"></img>
         <h3 data-aos="fade-down" >Phani Tejaswini</h3>
         <p className="role"> </p>
         <p className="inn">She has an experience of more than 6 years in areas of expertise are Accounts, Finance, Project Financing, Credit Appraisal, Auditing and Taxation. She was previously associated with Manufacturing and IT Industry.</p>
    </Col>
    <Col data-aos="flip-left" className="teammembers" lg={4} sm={12} md={12}>
       <img data-aos="flip-up" src={shravan} className="team_img"></img>
        <h3 data-aos="fade-down">Shravan M S</h3>
        <p className="role"></p>
        <p className="inn" >Shravan M S B.com,CMAMore than 5 years of diverse industries experience in all areas of Audit, Accounting and Cost Analysis Well geared to take up any professional assignments in the above areas. He is expertise in cost analysis, preparation of project profitability and payback model.</p>
    </Col>

    

    <Col data-aos="flip-left" className="teammembers" lg={4} sm={12} md={12}>
    <img data-aos="flip-up" src={bhargavi} className="team_img"></img>
         <h3 data-aos="fade-down">Bharagavinadh Reddy</h3>
         <p className="role"> </p>
         <p className="inn">She has an experience of 5 year in finance and has an experience working with MNCs in financial Planning and analysis. Currently acting as V.CFO of Manufacturing company.</p>
    </Col>
  </Row>



    </Container>
    </div>
  }
    </div>

  )
}

export default About