import React ,{useState,useEffect} from 'react'


import "../Services/Services.css"

import {Container,Row,Col, Button} from "react-bootstrap";
import GridLoader from "react-spinners/GridLoader";
function Service() {
  const [loading,setLoading]=useState(false)
  useEffect(()=>{
    setLoading(true)
    setTimeout(()=>{
        setLoading(false)
    },1000)
 },[])
  return (
    <div>
    
      
      <div className='services' id="service">
      <Container>
      <center data-aos="flip-up" className="serviceheading">Pick Your Service</center>
      <Row   className='sercicerow'>
      
      <Col data-aos="fade-up" className='servicescol' lg={3} md={12} sm={12}>
      <div className='icon'><i class="fa-solid fa-chart-line"></i></div>
      <p>Virtual CFO</p>
      <center><Button>Know More</Button></center>
      </Col>
      <Col data-aos="fade-up" className='servicescol' lg={3} md={12} sm={12}>
      <div className='icon'><i class="fa-solid fa-user"></i></div>
      <p>Accounting Services</p>
      <center><Button>Know More</Button></center>
      </Col>
      <Col data-aos="fade-up" className='servicescol' lg={3} md={12} sm={12}>
      <div className='icon'><i class="fa-solid fa-file"></i></div>
      <p>AR & AP</p>
      <center><Button>Know More</Button></center>
      </Col>
      <Col data-aos="fade-up" className='servicescol' lg={3} md={12} sm={12}>
      <div className='icon'><i class="fa-solid fa-circle-check"></i></div>
      <p>Audit & Assurance</p>
      <center><Button>Know More</Button></center>
      </Col>
      
      <Col data-aos="fade-left" className='servicescol' lg={3} md={3} sm={12}>
      <div className='icon'><i class="fa-solid fa-handshake"></i></div>
      <p>Taxation</p>
      <center><Button>Know More</Button></center>
      </Col>
      <Col data-aos="fade-left" className='servicescol' lg={3} md={6} sm={12}>
      <div className='icon'><i class="fa-solid fa-clipboard"></i></div>
      <p>Corporate Law</p>
      <center><Button>Know More</Button></center>
      </Col>
      <Col data-aos="fade-left" className='servicescol' lg={3} md={6} sm={12}>
      <div className='icon'><i class="fa-solid fa-hand-holding-dollar"></i></div>
      <p>Treasury Management</p>
      <center><Button>Know More</Button></center>
      </Col>
      <Col data-aos="fade-left" className='servicescol' lg={3} md={6} sm={12}>
      <div className='icon'><i class="fa-solid fa-ellipsis"></i></div>
      <p>Other Services</p>
      <center><Button>Know More</Button></center>
      </Col>
      
      </Row>
      
      
      </Container>
      
      
      
      </div>
    
    
    </div>
    
  )
}

export default Service