
import "../Navbar/navbar.css";
import React, { useState } from "react";

import { Link } from "react-scroll";


function Navbar() {
  
  
  const [isMobile,setIsMobile] = useState(false);
  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
      
    } else setActive("nav__menu");

    // Icon Toggler
    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler");
  };
  return (
   <div>   
   <nav className="nav a navposition" >
  <div className="nav__brand">
  
    <h2 ><span>P</span>RUDENCE</h2>
  </div>
   <ul className={active} 
   onClick={()=>{
     setActive("nav__menu");
     setIcon("nav__toggler");

   }
  }
   >

     
  
   
   <li className="nav__item">
   <Link className="nav__link" to="about" smooth={true} duration={200} offset={-70}>
          Why us
        </Link>
   </li>
   <li className="nav__item">
   
   <Link className="nav__link" to="mentor" smooth={true} duration={200} offset={-70}>
   Our Mentor
 </Link>
 </li>
 <li className="nav__item">
   <Link className="nav__link" to="team" smooth={true} duration={200} offset={-70}>
          Our Team
        </Link>
  
   </li>
   <li className="nav__item">
   
   <Link className="nav__link" to="service" smooth={true} duration={200} offset={-70}>
   Services
 </Link>
   
   
   </li>

  

   

   <li className="nav__item">
   <Link className="nav__link" to="contact" smooth={true} duration={200} offset={-70}>
          contact
        </Link>
   </li>


   </ul>
   <div onClick={navToggle} className={icon}>
     <div className="line1"></div>
     <div className="line2"></div>
     <div className="line3"></div>
   </div>
 </nav>
 </div>
  );
}

export default Navbar;

